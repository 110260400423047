/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { ACTION_TYPES, getPriceStr } from "./utils"
 import parse from "html-react-parser"
 export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
} 
 const IntroCopy = ({ description, lang, meta, title, searchParams, location, hitscount }) => {
 
   const {areas, bedrooms, pType, type, price } = searchParams;
 
   const actionStr = ACTION_TYPES[pType];
   const priceStr = getPriceStr(price)
  //  const bedRooms = searchParams.bedrooms
 //console.log("searchParams",searchParams)
   let propertyType = ""
 
   if(type === "") {
     propertyType = "property"
   } else {
     propertyType = type
   }
  //console.log("propertyType",propertyType,actionStr)
   const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
   }
 
   let desc = "";
   desc = "We have a generous choice of "+propertyType+" "+actionStr+" "+ toTitleCase(areas)+". For finding your dream new home effortlessly, you can filter and sort results. If you have any questions about your purchase, reach out to your local agents at Bentley Hurst."

  //  if ('london' !== areas) {
  //   desc = "Explore the range of property available for "+actionTxt+" in "+toTitleCase(areas)+". For more information about the properties available for "+actionTxt+" in "+toTitleCase(areas)+", please contact Anthony Pepe Estate Agents."
  // }
  
  // debugger
if(areas === "manchester"){
  if(pType === "lettings"){
    desc = "We have a generous choice of "+propertyType+" "+actionStr+" "+ toTitleCase(areas)+". You can filter and sort results to find your dream new home effortlessly. If you have any questions about your purchase, reach out to Bentley Hurst, your local <a href='/'>estate agents in Manchester</a>."

  }else if(pType === "sales"){
    desc = "We have a generous choice of "+propertyType+" "+actionStr+" "+ toTitleCase(areas)+". To find your dream new home effortlessly, you can filter and sort results. If you have any questions about your purchase, reach out to Bentley Hurst, your local <a href='/'>estate agents in Manchester</a>."
  }
}
  
  
  if(propertyType === 'property'){
    if(areas !== 'manchester' && bedrooms || areas !== 'manchester' && bedrooms && priceStr){
      desc = "Browse through our collection of stunning "+bedrooms+" bedroom property "+actionStr+" "+toTitleCase(areas)+". Viewings for properties can be booked easily on our website. If you would like any property purchasing advice, do get in touch with leading local agents at Bentley Hurst. "
    }
  else if(areas !== 'manchester' || areas !== 'manchester' && priceStr ){
    desc = "You have landed in the right place for property "+actionStr+" "+toTitleCase(areas)+". Available options can be found below. For more accurate results, feel free to use the filter feature, or for any property buying assistance, you can contact the Bentley Hurst team."
  }
  else if(bedrooms || bedrooms && priceStr){
    desc = "Your tailored results for "+bedrooms+" bedroom property "+actionStr+" "+toTitleCase(areas)+" can be found below. Browse through the excellent choice of properties on offer at Bentley Hurst. If you would like any property purchasing guidance, contact our friendly Bentley Hurst agents. "
  }
  else if(priceStr){
    desc = "Browse through our collection of stunning property "+actionStr+"  "+ toTitleCase(areas)+". Viewings for properties can be booked easily on our website. If you would like any property buying assistance, do get in touch with your leading local agents at Bentley Hurst."
  }
}
else {
  if(areas !== 'manchester' && propertyType && bedrooms || areas !== 'manchester' && propertyType && bedrooms) {
    desc = "Your tailored results for "+bedrooms+" bedroom "+propertyType+" "+actionStr+" "+toTitleCase(areas)+" are below. Browse through the excellent choice of properties on offer at Bentley Hurst. We welcome any questions you may have about purchasing a new home. "
  }

  else if(propertyType && bedrooms || propertyType && bedrooms && priceStr){
    desc = "You have landed in the right place for "+bedrooms+" bedroom "+propertyType+" "+actionStr+"  "+toTitleCase(areas)+". At Bentley Hurst, we offer a quality selection of properties. If you would like any property purchasing assistance, you can reach out to our agents at any time. "
  }
  else if(areas !== 'manchester' && propertyType || areas !== 'manchester' && propertyType && priceStr){
    desc = "Browse through our excellent choice of "+propertyType+" "+actionStr+"  "+toTitleCase(areas)+". You can whittle down options by filtering results. If you would benefit from property buying assistance, do get in touch with our friendly agents at Bentley Hurst. "
  }
  else if(propertyType){
     desc = `We have a generous choice of ${propertyType} ${actionStr} ${toTitleCase(areas)}.  Explore a variety of new ${propertyType} ${actionStr} ${toTitleCase(areas)}. Easily refine your search with our filtering options. For a local area guide, contact Bentley hurst, your trusted <a href="/">estate agents in manchester</a>.`
  }
  else if(propertyType || propertyType && priceStr) {
    desc = "We have a generous choice of "+propertyType+" "+actionStr+"  "+toTitleCase(areas)+". For more accurate results, you can use the filter feature below. The agents at Bentley Hurst are more than happy to answer any property buying questions you may have."
  }

}
     let titlePropertyType = ""
 
     if(type === "") {
         titlePropertyType = "Property"
     } else if(type === "apartment") {
         titlePropertyType = "Apartments"
     } else if(type === "house") {
         titlePropertyType = "Houses"
     } else if(type === "maisonette") {
         titlePropertyType = "Maisonette"
     } else if(type === "property") {
         titlePropertyType = "Property"
     } else if(type === "bungalow") {
         titlePropertyType = "Bungalow"
     } else if(type === "land") {
         titlePropertyType = "Land"
     } else if(type === "commercial") {
         titlePropertyType = "Commercial"
     } else {
         titlePropertyType = type
     }
 
     let bedroomType = ""
 
     if ( bedrooms ) {
       bedroomType = bedrooms + ' + Bedroom '
     }
 
     let descTitle = bedroomType + capitalize(titlePropertyType) + actionStr + capitalize(areas) + priceStr
     //
 
   // console.log("Props Desc => ", desc);
   if(hitscount <= 0){
    desc = "Currently, we don't have a "+bedrooms+" bedroom "+propertyType+" "+actionStr+" "+toTitleCase(areas)+" listed. Whether buying, selling or renting property, get in touch with our expert estate agents at Bentley Hurst for support!"
   }
 
   description = desc
 
   let metaItems = [
     {
       name: `description`,
       content: description,
     },
     {
       property: `og:title`,
       content: descTitle,
     },
     {
       property: `og:description`,
       content: description,
     },
     {
       property: `og:type`,
       content: `website`,
     },
     {
       name: `twitter:card`,
       content: `summary`,
     },
     {
       name: `twitter:creator`,
       content: `Bentley Hurst`,
     },
     {
       name: `twitter:title`,
       content: descTitle,
     },
     {
       name: `twitter:description`,
       content: description,
     },
   ].concat(meta);
 
 
   // set no index for result pagination
   let hasPagination = false;
   if (location !== undefined && location.pathname) {
     let pageStr = location.pathname.split('page-');
     hasPagination = pageStr[1] || false;
   }
   if ( hasPagination ) {
     metaItems.push({
       name: `robots`,
       content: `noindex`,
     })
   }
 
   return (
    <p className="intro-text">
        {parse(desc)}
        {/* Browse through our collection of stunning {h1}. Viewings for properties can be booked easily on our website. If you would like any property advice, please <Link to={'/contact-manchester-estate-agents'}>contact Bentley Hurst Estate agents</Link>. */}
    </p>     
   )
 }
 
 IntroCopy.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   title: ``,
   searchParams: [],
 }
 
 IntroCopy.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   searchParams: PropTypes.any,
 }
 
 export default IntroCopy
 